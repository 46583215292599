<template>
    <el-dialog title="查看" width="80%" top="2vh" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
        <div class="dialogBox">
            <el-descriptions title="订单信息" :column="3" class="b_b p_b1">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="交易平台号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="支付方式">
<!--                    {{$dictUtils.getDictLabel("pay_platform",info.payPlatform,'')}}-->
                </el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
                <el-descriptions-item label="支付状态">
                    <el-tag size="small" v-if="info.payState">
                        {{$dictUtils.getDictLabel("order_status",info.payState,'')}}
                    </el-tag>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="参观信息" :column="2" class="b_b p_t2 p_b1">
                <el-descriptions-item label="参观票">湖湘之美基本陈列</el-descriptions-item>
                <el-descriptions-item label="参观日期">{{info.makeDate}}</el-descriptions-item>
                <el-descriptions-item label="入馆时段">{{info.storehouseTime}}</el-descriptions-item>
                <el-descriptions-item label="预约状态">
                    {{$dictUtils.getDictLabel("ticketing_state",info.ticketingState,'')}}
                </el-descriptions-item>
                <el-descriptions-item label="团体类型">{{info.ticketingTeamTypeName}}</el-descriptions-item>
                <el-descriptions-item label="团体名称">{{info.ticketingTeamName}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{info.ticketingVerbName}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.ticketingVerbPhone}}</el-descriptions-item>
                <el-descriptions-item label="身份证号">{{info.ticketingVerbCard}}</el-descriptions-item>
                <el-descriptions-item label="团体人数">{{info.ticketingNoun}}</el-descriptions-item>
                <el-descriptions-item label="总价（元）">{{info.countMoney}}</el-descriptions-item>
                <el-descriptions-item label="审核状态">
                    <el-tag size="small" v-if="info.ticketingAuditStatus">
                        {{$dictUtils.getDictLabel("ticketing_auditStatus",info.ticketingAuditStatus,'')}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="审核时间">{{info.ticketingTime}}</el-descriptions-item>
                <el-descriptions-item label="审核备注">{{info.ticketingAuditRemake}}</el-descriptions-item>
            </el-descriptions>
            <div class="el-descriptions__title el-descriptions p_t2 p_b1">门票信息</div>
            <el-table :data="ticketList" border size="small" v-loading="ticketLoading" style="width: 100%">
                <el-table-column type="index" label="编号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="身份证号码"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingValidationCode" label="票号"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingMoney" label="单价" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorState" label="参观状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("visitor_state",scope.row.ticketingVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingValidationTime" label="参观时间" width="140"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="createOutRequestDate" label="退票时间" width="140"
                                 show-overflow-tooltip></el-table-column>
            </el-table>
            <el-pagination @size-change="ticketSizeChangeHandle"
                           @current-change="ticketCurrentChangeHandle"
                           :current-page="ticketCurrent"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size="ticketSize"
                           :total="ticketTotal"
                           background
                           layout="total, prev, pager, next, sizes, jumper">
            </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
                ticketLoading: true,
                ticketList: [],
                ticketTotal: 0,
                ticketSize: 10,
                ticketCurrent: 1,
                makeTeamId: null,
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.makeTeamId = row.id
                this.$axios(this.api.order.getTicketingMakeTeamById, {makeTeamId: row.id}).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    }
                })
                this.queryTicket(1)
            },
            queryTicket(current) {
                if (current) {
                    this.ticketCurrent = current
                }
                this.$axios(this.api.order.getTicketingMakeDetailById, {
                    makeTeamId: this.makeTeamId,
                    current: this.ticketCurrent,
                    size: this.ticketSize
                }).then((res) => {
                    if (res.status) {
                        this.ticketList = res.data.records
                        this.ticketTotal = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.ticketLoading = false
                })
            },
            // 门票每页数
            ticketSizeChangeHandle(val) {
                this.ticketSize = val
                this.ticketCurrent = 1
                this.queryTicket()
            },
            // 门票当前页
            ticketCurrentChangeHandle(val) {
                this.ticketCurrent = val
                this.queryTicket()
            },
        }
    }
</script>

<style scoped>

</style>
