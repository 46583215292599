<template>
    <div class="page">
        <div class="query-form p_b1">
            <div class="m_b1">
                <span class="fontSize14">参观日期</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="createDate"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" style="width: 30%">
                </el-date-picker>
                <el-input class="m_r1" size="small" v-model="searchForm.keyWords" maxlength="50"
                          placeholder="订单编号/联系人/联系电话/参观人姓名/参观人证件号码" clearable style="width: 30%"></el-input>
            </div>
            <div>
                <span class="fontSize14">入馆时段</span>
                <el-time-picker class="m_l1 m_r1" size="small"
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                                v-model="searchForm.makeStartTime" placeholder="开始时段" style="width: 13.7%">
                </el-time-picker>
                <span class="fontSize14">至</span>
                <el-time-picker class="m_l1 m_r1" size="small"
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                                v-model="searchForm.makeEndTime" placeholder="结束时段" style="width: 13.3%">
                </el-time-picker>
                <el-select class="m_r1" clearable v-model="searchForm.ticketingState" placeholder="预约状态" size="small"
                           style="width: 30%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('teamTicketing_state')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="queryData(1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </div>
        </div>
        <div class="bg-white">
            <div class="flex_b_c">
                <el-radio-group v-model="searchForm.ticketingAuditStatus" size="small">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">待审核</el-radio-button>
                    <el-radio-button label="2">审核通过</el-radio-button>
                    <el-radio-button label="1">审核失败</el-radio-button>
                </el-radio-group>
                <div>
                    <el-button type="primary" :disabled="dataListSelections.length <= 0" @click="audit()" size="small">审核</el-button>
                    <el-button type="primary" :disabled="orderList.length <= 0" @click="exportData()" size="small">导出</el-button>
                </div>
            </div>
            <el-table :data="orderList"
                      v-loading="orderLoading"
                      @selection-change="selectionChangeHandle"
                      size="small" ref="orderTable"
                      height="calc(100vh - 350px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="outTradeNo" label="订单编号" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column label="展览名称" width="120" show-overflow-tooltip>湖湘之美基本陈列</el-table-column>
                <el-table-column prop="ticketingVerbName" label="联系人" width="100"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVerbPhone" label="联系电话" width="100"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="makeDate" label="参观日期" sortable width="140"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="storehouseTime" label="入馆时段" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingNoun" label="团体人数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingAuditStatus" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("ticketing_auditStatus",scope.row.ticketingAuditStatus,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingTime" label="审核时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingState" label="预约状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("teamTicketing_state",scope.row.ticketingState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="下单时间" show-overflow-tooltip
                                 width="120"></el-table-column>
                <el-table-column fixed="right" width="140" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('teamOrderList:view')" icon="el-icon-view" type="text"
                                   size="mini" @click="view(scope.row)">查看
                        </el-button>
                        <el-button v-if="hasPermission('teamOrderList:audit') && scope.row.ticketingAuditStatus == 0"
                                   icon="el-icon-s-check" type="text" size="mini" @click="audit([scope.row.id])">审核
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="text_center"
                           :current-page="searchForm.current"
                           :page-size="searchForm.size"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="orderTotal"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="审核" width="40%" :close-on-click-modal="false" :visible.sync="visible">
            <el-form size="small" :model="auditForm" ref="auditForm" label-width="100px">
                <el-form-item label="审核状态：" prop="ticketingAuditStatus"
                              :rules="[{required: true, message: '请选择审核状态', trigger: 'change'}]">
                    <el-radio-group v-model="auditForm.ticketingAuditStatus">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="1">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" prop="ticketingAuditRemake">
                    <el-input type="textarea" :rows="3" v-model.trim="auditForm.ticketingAuditRemake" maxlength="200"
                              show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button type="primary" size="small" @click="confirm">确定</el-button>
            </span>
        </el-dialog>
        <!--查看-->
        <TeamOrderView ref="teamOrderView"></TeamOrderView>
    </div>
</template>

<script>
    import TeamOrderView from './teamOrderView'

    export default {
        components: {TeamOrderView},
        data() {
            return {
                createDate: [],
                searchForm: {
                    keyWords: '',
                    makeStartDate: '',
                    makeEndDate: '',
                    makeStartTime: '',
                    makeEndTime: '',
                    ticketingAuditStatus: '',
                    ticketingState: '',
                    current: 1,
                    size: 10,
                },
                // 订单列表
                orderList: [],
                orderLoading: false,
                orderTotal: 0,
                dataListSelections: [],
                // 审核
                visible: false,
                auditForm: {
                    ticketingAuditStatus: null,
                    ticketingAuditRemake: '',
                    ticketingMakeTeamId: [],
                },
                auditIds: [],
            }
        },
        watch: {
            'searchForm.ticketingAuditStatus'() {
                this.queryData(1)
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 数据查询 type: 0订单；1人员
            queryData(current) {
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.makeStartDate = this.createDate ? this.createDate[0] : ''
                param.makeEndDate = this.createDate ? this.createDate[1] : ''
                if (current) {
                    param.current = current
                    this.searchForm.current = current
                } else {
                    param.current = this.searchForm.current
                }
                this.orderLoading = true
                this.$axios(this.api.order.sysGetTicketingMakeTeam, param, 'post').then((res) => {
                    if (res.status) {
                        this.orderList = res.data.records
                        this.orderTotal = parseInt(res.data.total)
                        this.$nextTick(() => {
                            this.$refs.orderTable.doLayout();
                        })
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.orderLoading = false
                })
            },
            checkSelectable(row) {
                return row.ticketingAuditStatus == '0'
            },
            // 审核
            audit(id) {
                this.visible = true
                this.$nextTick(() => {
                    this.$refs.auditForm.resetFields();
                    this.auditForm.ticketingMakeTeamId = id || this.dataListSelections.map(item => {
                        return item.id
                    })
                })
            },
            // 确定审核
            confirm() {
                this.$refs['auditForm'].validate((valid) => {
                    if (valid) {
                        if (this.auditForm.ticketingAuditStatus == 1 && this.auditForm.ticketingAuditRemake == '') {
                            this.$message('请输入备注信息');
                            return
                        }
                        this.$axios(this.api.order.ticketingMakeTeamToExamine, this.auditForm, 'post').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功')
                                this.visible = false
                                this.queryData()
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 重置
            resetSearch() {
                this.createDate = []
                this.searchForm = {
                    keyWords: '',
                    makeStartDate: '',
                    makeEndDate: '',
                    makeStartTime: '',
                    makeEndTime: '',
                    ticketingAuditStatus: '',
                    ticketingState: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 导出
            exportData() {
                let makeStartDate = this.createDate.length ? this.createDate[0] : ''
                let makeEndDate = this.createDate.length ? this.createDate[1] : ''
                window.open(this.$globalUrl() + this.api.order.teamOrderExPortList + '?makeStartDate=' + makeStartDate + '&makeEndDate=' + makeEndDate + '&keyWords=' + this.searchForm.keyWords + '&makeStartTime=' + this.searchForm.makeStartTime + '&makeEndTime=' + this.searchForm.makeEndTime + '&ticketingAuditStatus=' + this.searchForm.ticketingAuditStatus + '&ticketingState=' + this.searchForm.ticketingState)
            },
            // 查看
            view(row) {
                this.$refs.teamOrderView.init(row)
            },
            // 订单每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val;
                this.searchForm.current = 1;
                this.queryData()
            },

            // 订单当前页
            currentChangeHandle(val) {
                this.searchForm.current = val;
                this.queryData()
            },
        }

    }
</script>

<style scoped>

</style>
